import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button } from "react-bootstrap"

const InquiryResult = () => (
  <Layout>
    <Seo title="お問い合わせ完了 | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | ワカチエ" />
    <div className="inquiryResult contentsPage">
      <div className="content">
        <h1>お問い合わせ<br/>ありがとうございます。</h1>
        <p>
          この度はお問い合わせいただきありがとうございます。<br/>
          追って弊社営業担当よりご連絡申し上げます。
        </p>
      </div>
    </div>
  </Layout>
)

export default InquiryResult
